import { css } from 'styled-components/macro';

export const fadeInOut = () => css`
    @keyframes inAnimation {
        0% {
            opacity: 0;
            visibility: hidden;
        }
        100% {
            opacity: 1;
            visibility: visible;
        }
    }
    
    @keyframes outAnimation {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            visibility: hidden;
        }
    }
`;