const ShushingFaceIcon = ({ ...props }) => (
    <svg 
        width={props.width}
        height={props.height}
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M40.475 68.95C57.3059 68.95 70.95 55.3059 70.95 38.475C70.95 21.6441 57.3059 8 40.475 8C23.6441 8 10 21.6441 10 38.475C10 55.3059 23.6441 68.95 40.475 68.95Z" fill="url(#paint0_radial)"/>
        <path opacity="0.5" d="M40.475 68.95C57.3059 68.95 70.95 55.3059 70.95 38.475C70.95 21.6441 57.3059 8 40.475 8C23.6441 8 10 21.6441 10 38.475C10 55.3059 23.6441 68.95 40.475 68.95Z" fill="url(#paint1_radial)"/>
        <path d="M40.475 68.95C57.3059 68.95 70.95 55.3059 70.95 38.475C70.95 21.6441 57.3059 8 40.475 8C23.6441 8 10 21.6441 10 38.475C10 55.3059 23.6441 68.95 40.475 68.95Z" fill="url(#paint2_radial)"/>
        <path d="M33.1752 17.2134C32.8633 15.7818 31.375 15.1581 29.1638 15.64C27.2786 16.0511 23.409 18.1914 21.5096 23.2092C21.1553 24.1305 22.0624 24.3998 22.4735 23.762C23.8342 21.5933 28.044 18.2765 31.8144 17.9646C33.3169 17.8512 33.1752 17.2134 33.1752 17.2134Z" fill="url(#paint3_linear)"/>
        <path d="M30.5246 33.1029C32.145 33.1029 33.4587 31.1166 33.4587 28.6663C33.4587 26.216 32.145 24.2297 30.5246 24.2297C28.9041 24.2297 27.5905 26.216 27.5905 28.6663C27.5905 31.1166 28.9041 33.1029 30.5246 33.1029Z" fill="url(#paint4_radial)"/>
        <path d="M30.5246 25.7322C31.7861 25.7322 32.8775 26.8236 33.4587 28.4537C33.4161 26.0582 32.1263 24.1305 30.5246 24.1305C28.9228 24.1305 27.633 26.0582 27.5905 28.4537C28.1716 26.8378 29.263 25.7322 30.5246 25.7322Z" fill="url(#paint5_linear)"/>
        <path d="M50.4254 33.1029C52.0459 33.1029 53.3595 31.1166 53.3595 28.6663C53.3595 26.216 52.0459 24.2297 50.4254 24.2297C48.805 24.2297 47.4913 26.216 47.4913 28.6663C47.4913 31.1166 48.805 33.1029 50.4254 33.1029Z" fill="url(#paint6_radial)"/>
        <path d="M50.4254 25.8456C49.1639 25.8456 48.0725 26.937 47.4913 28.5671C47.5339 26.1716 48.8237 24.2439 50.4254 24.2439C52.0271 24.2439 53.317 26.1716 53.3595 28.5671C52.7784 26.937 51.687 25.8456 50.4254 25.8456Z" fill="url(#paint7_linear)"/>
        <path d="M40.475 68.95C57.3059 68.95 70.95 55.3059 70.95 38.475C70.95 21.6441 57.3059 8 40.475 8C23.6441 8 10 21.6441 10 38.475C10 55.3059 23.6441 68.95 40.475 68.95Z" fill="url(#paint8_radial)"/>
        <path d="M46.981 56.8734C47.2362 52.4085 46.7401 49.2476 43.1965 48.794C42.2326 48.6664 42.8988 45.5906 42.7145 45.6473C38.9867 46.7529 33.3878 57.8798 33.3878 57.8798L33.4303 57.8656C33.0618 58.5318 32.7499 59.2547 32.5373 60.0768C31.7435 63.2235 31.5734 65.7466 31.8002 67.7027C34.5501 68.5248 37.4558 68.9642 40.475 68.9642C42.2609 68.9642 44.0044 68.8083 45.7053 68.5106C46.1873 67.3199 46.8818 65.7891 48.441 63.4362C50.2553 60.7147 52.2397 56.193 50.5246 54.4921C49.4757 53.4148 48.4835 55.3709 46.981 56.8734Z" fill="url(#paint9_linear)"/>
        <path d="M40.4751 46.2426C43.6769 46.2426 46.2724 45.1194 46.2724 43.7337C46.2724 42.3481 43.6769 41.2249 40.4751 41.2249C37.2733 41.2249 34.6777 42.3481 34.6777 43.7337C34.6777 45.1194 37.2733 46.2426 40.4751 46.2426Z" fill="url(#paint10_radial)"/>
        <path d="M40.4751 41.9903C42.9839 41.9903 45.1526 42.5998 46.2582 43.4928C45.9889 42.2171 43.5084 41.2107 40.4751 41.2107C37.4559 41.2107 34.9754 42.2171 34.6919 43.4928C35.7975 42.5998 37.9662 41.9903 40.4751 41.9903Z" fill="url(#paint11_linear)"/>
        <path d="M34.224 68.9642C34.224 68.9642 33.8838 71.2888 34.9044 71.2888C35.925 71.2888 39.341 71.062 41.212 69.0209C43.083 66.9798 43.8059 66.9231 47.1794 61.8628C48.9938 59.1413 52.2397 56.1788 50.5388 54.4779C48.8378 52.777 45.2942 56.8592 43.9477 58.8011C42.6011 60.743 40.3048 55.1583 40.3048 55.1583L33.7137 58.5743L34.224 68.9642Z" fill="url(#paint12_linear)"/>
        <path d="M34.224 68.9642C34.224 68.9642 33.8838 71.2888 34.9044 71.2888C35.925 71.2888 39.341 71.062 41.212 69.0209C43.083 66.9798 43.8059 66.9231 47.1794 61.8628C48.9938 59.1413 52.2397 56.1788 50.5388 54.4779C48.8378 52.777 45.2942 56.8592 43.9477 58.8011C42.6011 60.743 40.3048 55.1583 40.3048 55.1583L33.7137 58.5743L34.224 68.9642Z" fill="url(#paint13_linear)"/>
        <path d="M43.9478 58.7869C42.9131 60.2753 41.3256 57.3412 40.6452 55.8954C40.3475 56.2922 39.4829 55.2433 39.171 55.782C37.1299 59.4673 38.3064 59.5665 38.1505 61.0123C37.9237 63.181 40.0215 64.8961 40.0215 64.8961C40.0215 64.8961 40.4184 62.8692 44.6424 62.0754C47.1796 61.5935 47.2788 56.6891 47.6048 54.889C46.159 55.9237 44.7132 57.6955 43.9478 58.7869Z" fill="url(#paint14_linear)"/>
        <path d="M40.1206 39.4814C38.6749 39.4814 37.6968 40.5445 37.8669 45.6189C38.037 50.6933 36.0809 54.9173 36.0809 54.9173L42.0058 51.1186C42.0058 51.1186 41.7082 45.3638 41.9633 43.3227C42.2185 41.2815 42.2043 39.4814 40.1206 39.4814Z" fill="url(#paint15_linear)"/>
        <path d="M40.1206 39.4814C38.6749 39.4814 37.6968 40.5445 37.8669 45.6189C38.037 50.6933 36.0809 54.9173 36.0809 54.9173L42.0058 51.1186C42.0058 51.1186 41.7082 45.3638 41.9633 43.3227C42.2185 41.2815 42.2043 39.4814 40.1206 39.4814Z" fill="url(#paint16_linear)"/>
        <path d="M41.9492 43.3227C42.1901 41.3808 42.2043 39.6798 40.4325 39.5097C41.8074 39.7649 41.6373 42.5714 41.0987 44.485C40.5317 46.5261 41.4389 48.1278 40.8719 50.339C40.6593 51.1469 41.2121 51.1328 41.9775 50.8068C41.9208 49.5594 41.7365 45.0803 41.9492 43.3227Z" fill="url(#paint17_linear)"/>
        <path d="M37.4418 52.2809C37.4418 52.2809 38.434 49.8713 38.3773 45.7182C38.3206 41.9052 38.6041 40.8705 39.4262 39.5806C38.3773 39.9492 37.7111 41.395 37.8528 45.6048C37.9379 47.9861 37.541 50.1831 37.1016 51.8415L37.4418 52.2809Z" fill="url(#paint18_linear)"/>
        <path d="M32.9625 56.6041C32.9625 56.6041 34.5075 49.4177 42.0342 49.4177C48.8804 49.4177 46.1731 62.0754 42.7145 61.5793C38.5898 60.984 40.5317 53.5991 40.5317 53.5991L32.9625 56.6041Z" fill="url(#paint19_linear)"/>
        <path d="M32.9625 56.6041C32.9625 56.6041 34.5075 49.4177 42.0342 49.4177C48.8804 49.4177 46.1731 62.0754 42.7145 61.5793C38.5898 60.984 40.5317 53.5991 40.5317 53.5991L32.9625 56.6041Z" fill="url(#paint20_linear)"/>
        <path d="M40.9286 61.6927C42.7996 62.3164 45.1384 60.7288 45.1384 56.1789C45.1384 52.6494 42.8989 50.9201 41.5523 50.4382C41.0704 50.2681 36.5912 49.1767 32.9626 56.6041C29.3339 64.0315 40.2199 56.0938 40.2199 56.0938L40.9286 61.6927Z" fill="url(#paint21_linear)"/>
        <path d="M41.5523 50.4382C41.283 50.339 39.8372 49.9846 37.9803 50.8776C40.2199 50.1405 43.5651 50.7784 44.1887 55.4134C44.7841 59.7366 42.4028 61.5368 40.8861 61.3809L40.9286 61.6927C42.7996 62.3164 45.1384 60.7288 45.1384 56.1788C45.1384 52.6494 42.8847 50.9201 41.5523 50.4382Z" fill="url(#paint22_linear)"/>
        <path d="M39.7379 57.3128C38.5898 58.3759 39.2277 61.6927 40.9286 61.6927C42.6295 61.6927 43.4233 59.5382 43.6501 57.0293C43.8769 54.5346 42.346 51.8557 40.064 51.8557C37.7819 51.8557 33.3595 53.8401 32.1121 58.7869C29.9859 67.2065 32.3389 71.2888 34.8903 71.2888C36.1518 71.2888 37.3566 59.524 39.7379 57.3128Z" fill="url(#paint23_linear)"/>
        <path d="M39.7379 57.3128C38.5473 58.3334 39.2277 61.6927 40.9286 61.6927C42.6295 61.6927 43.4233 59.5382 43.6501 57.0293C43.8769 54.5346 42.346 51.8557 40.064 51.8557C37.7819 51.8557 33.3595 53.8401 32.1121 58.7869C29.9859 67.2065 32.3389 71.2888 34.8903 71.2888C36.1518 71.2888 36.0668 60.4453 39.7379 57.3128Z" fill="url(#paint24_linear)"/>
        <path d="M35.6415 70.1123C31.2049 69.1059 32.5799 56.5332 36.7613 52.8053C34.8336 53.8401 32.8917 55.7536 32.1263 58.7728C30.0001 67.1924 32.3531 71.2746 34.9045 71.2746C35.7407 71.2746 38.1788 71.1187 40.064 69.9422C39.2277 70.3533 37.8386 70.6226 35.6415 70.1123Z" fill="url(#paint25_linear)"/>
        <path d="M40.4466 61.5935C40.5884 61.6502 40.7585 61.6927 40.9286 61.6927C42.6295 61.6927 43.4232 59.5382 43.65 57.0293C43.8768 54.5346 42.346 51.8557 40.0639 51.8557C39.9222 51.8557 39.7804 51.8698 39.6245 51.884C41.1553 52.1817 42.8704 53.996 42.8704 55.5694C42.8988 58.1066 42.6862 61.4092 40.4466 61.5935Z" fill="url(#paint26_linear)"/>
        <path d="M36.9313 53.4148C39.256 51.3454 42.0483 52.2384 42.6437 54.9173C43.239 57.5963 41.8074 57.5396 40.2198 56.0938C38.6323 54.648 37.3282 55.2292 36.251 56.3206C35.1595 57.3978 34.1957 55.8387 36.9313 53.4148Z" fill="url(#paint27_radial)"/>
        <path d="M47.7748 17.2134C48.0866 15.7818 49.575 15.1581 51.7862 15.64C53.6714 16.0511 57.541 18.1914 59.4404 23.2092C59.7947 24.1305 58.8876 24.3998 58.4765 23.762C57.1158 21.5933 52.906 18.2765 49.1356 17.9646C47.6331 17.8512 47.7748 17.2134 47.7748 17.2134Z" fill="url(#paint28_linear)"/>
        <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.479 26.1245) scale(36.7641)">
        <stop stopColor="#FFDF30"/>
        <stop offset="1" stopColor="#FFB82E"/>
        </radialGradient>
        <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.479 26.1245) scale(28.924)">
        <stop stopColor="#FFE95F"/>
        <stop offset="1" stopColor="#FFBB47" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(39.9766 55.2518) rotate(90) scale(20.3326 8.65356)">
        <stop stopColor="#7A4400" stopOpacity="0.5"/>
        <stop offset="0.6008" stopColor="#894D00" stopOpacity="0.1981"/>
        <stop offset="0.995" stopColor="#965500" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint3_linear" x1="27.4116" y1="20.9702" x2="26.5949" y2="17.2055" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200"/>
        <stop offset="1" stopColor="#7A4400"/>
        </linearGradient>
        <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.6354 28.7828) rotate(73.8539) scale(4.30589 2.78584)">
        <stop offset="0.00132565" stopColor="#7A4400"/>
        <stop offset="1" stopColor="#643800"/>
        </radialGradient>
        <linearGradient id="paint5_linear" x1="30.5253" y1="24.2108" x2="30.5253" y2="28.3467" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200"/>
        <stop offset="1" stopColor="#512D00"/>
        </linearGradient>
        <radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.5357 28.7835) rotate(73.8539) scale(4.30589 2.78584)">
        <stop offset="0.00132565" stopColor="#7A4400"/>
        <stop offset="1" stopColor="#643800"/>
        </radialGradient>
        <linearGradient id="paint7_linear" x1="50.4247" y1="24.3196" x2="50.4247" y2="28.4555" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200"/>
        <stop offset="1" stopColor="#512D00"/>
        </linearGradient>
        <radialGradient id="paint8_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(41.475 65.5824) scale(20.5019 9.99878)">
        <stop stopColor="#7A4400" stopOpacity="0.5"/>
        <stop offset="0.6008" stopColor="#894D00" stopOpacity="0.1981"/>
        <stop offset="0.995" stopColor="#965500" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint9_linear" x1="31.7009" y1="57.2951" x2="51.1864" y2="57.2951" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7A4400" stopOpacity="0.5"/>
        <stop offset="0.6008" stopColor="#894D00" stopOpacity="0.1981"/>
        <stop offset="0.995" stopColor="#965500" stopOpacity="0"/>
        </linearGradient>
        <radialGradient id="paint10_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.1218 43.8799) rotate(56.2273) scale(3.52202 3.94129)">
        <stop offset="0.00132565" stopColor="#7A4400"/>
        <stop offset="1" stopColor="#643800"/>
        </radialGradient>
        <linearGradient id="paint11_linear" x1="40.4751" y1="40.0334" x2="40.4751" y2="44.1306" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200"/>
        <stop offset="1" stopColor="#512D00"/>
        </linearGradient>
        <linearGradient id="paint12_linear" x1="33.7133" y1="62.6768" x2="51.015" y2="62.6768" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#FFCA4B"/>
        <stop offset="1" stopColor="#FFD648"/>
        </linearGradient>
        <linearGradient id="paint13_linear" x1="44.7902" y1="66.1729" x2="38.0134" y2="56.029" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint14_linear" x1="51.6911" y1="67.5438" x2="40.1834" y2="56.377" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint15_linear" x1="36.0717" y1="47.1955" x2="42.0688" y2="47.1955" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#FFCB4B"/>
        <stop offset="1" stopColor="#FFD748"/>
        </linearGradient>
        <linearGradient id="paint16_linear" x1="38.1177" y1="45.2609" x2="40.6181" y2="52.4213" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint17_linear" x1="38.282" y1="44.1442" x2="45.7256" y2="47.296" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint18_linear" x1="34.2273" y1="42.6908" x2="41.956" y2="48.6577" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint19_linear" x1="32.9662" y1="55.4997" x2="46.2789" y2="55.4997" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#FFCA4B"/>
        <stop offset="1" stopColor="#FFD648"/>
        </linearGradient>
        <linearGradient id="paint20_linear" x1="46.9144" y1="52.2035" x2="24.6235" y2="65.3735" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint21_linear" x1="32.2254" y1="56.0487" x2="45.1358" y2="56.0487" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#FFCA4B"/>
        <stop offset="1" stopColor="#FFD648"/>
        </linearGradient>
        <linearGradient id="paint22_linear" x1="34.7796" y1="49.0812" x2="53.2488" y2="68.0619" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint23_linear" x1="31.2946" y1="61.5731" x2="43.6806" y2="61.5731" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#FFCA4B"/>
        <stop offset="1" stopColor="#FFD648"/>
        </linearGradient>
        <linearGradient id="paint24_linear" x1="31.2946" y1="61.5731" x2="43.6806" y2="61.5731" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#FFCA4B"/>
        <stop offset="1" stopColor="#FFD648"/>
        </linearGradient>
        <linearGradient id="paint25_linear" x1="32.4363" y1="55.4367" x2="47.3253" y2="96.9214" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <linearGradient id="paint26_linear" x1="37.0844" y1="54.5795" x2="52.5133" y2="63.8283" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFBC47" stopOpacity="0"/>
        <stop offset="1" stopColor="#C86F34"/>
        </linearGradient>
        <radialGradient id="paint27_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.2301 54.4543) rotate(-34.0754) scale(3.70665 1.6136)">
        <stop stopColor="#FBE07A"/>
        <stop offset="1" stopColor="#FFE75B" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint28_linear" x1="53.543" y1="20.9657" x2="54.3597" y2="17.2009" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200"/>
        <stop offset="1" stopColor="#7A4400"/>
        </linearGradient>
        </defs>
    </svg>
  );
  
  export default ShushingFaceIcon;
  