import InputText from 'components/ui/forms/InputText';

const BasicSearch = () => {
  return (
    <>
      <InputText placeholder={'Select Podcast First'} />
    </>
  );
};

export default BasicSearch;
